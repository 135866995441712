<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable>
      <v-card v-if="ticket && ticket._id">
        <v-card-title class="primary white--text">
          Edit Ticket Details
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-form ref="formEditTicket">
              <v-row>
                <v-col cols="12" xs="12" md="12">
                  <p class="text-h6">Ticket Details</p>
                  <v-simple-table dense style="width:90vw">
                    <tbody>
                      <tr>
                        <td class="text-left">QC / Document Reference:</td>
                        <td>
                          <v-text-field
                            v-model="ticket.DocumentRef"
                            clearable
                            :rules="[
                              (v) => !!v || 'Document reference is required',
                            ]"
                          ></v-text-field>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left">Company Name:</td>
                        <td>
                          <!-- <v-text-field
                              v-model="ticket.CompanyName"
                              required
                              :rules="rules.CompanyName"
                            ></v-text-field> -->

                          <v-combobox
                            :items="companyList"
                            v-model="ticket.CompanyName"
                            :rules="[(v) => !!v || 'Company name is required']"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Site Name</td>
                        <td>
                          <v-text-field
                            v-model="ticket.SiteName"
                          ></v-text-field>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left">Driver:</td>
                        <td>
                          <v-combobox
                            v-model="ticket.DriverName"
                            required
                            :items="driverList"
                            :rules="[(v) => !!v || 'Driver name is required']"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td class="text-left">Vehicle registration:</td>

                        <td>
                          <v-combobox
                            v-model="ticket.VehicleReg"
                            required
                            :items="tractorList"
                            :rules="[
                              (v) => !!v || 'Vehicle registration is required',
                            ]"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">Trailer Number:</td>
                        <td>
                          <!-- <v-text-field
                      v-model="ticket.TrailerNumber"
                      :rules="rules.TrailerNumber"
                    ></v-text-field> -->

                          <v-combobox
                            v-model="ticket.TrailerNumber"
                            :items="trailerList"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td>
                          <v-text-field
                            type="text"
                            v-model="ticket.InboundComment"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" xs="12" md="12">
                <p class="text-h6">Recorded Weights</p>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Weight</th>
                      <th>Value</th>
                      <th>Date / Time</th>
                      <th>Direction</th>
                      <th>Laden / Unladen</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Weight1</td>
                      <td>{{ ticket.InboundWeightKG }}&nbsp;KG</td>
                      <td>
                        {{
                          moment(ticket.InboundDateTime).format(
                            "DD/MM/YYYY hh:mm"
                          )
                        }}
                      </td>
                      <td>{{ ticket.InboundInstrument }}</td>
                      <td>{{ ticket.InboundType }}</td>
                    </tr>

                    <tr v-if="ticket.TareWeightKG">
                      <td>
                        Tare Weight
                      </td>
                      <td>{{ ticket.TareWeightKG }}&nbsp;KG</td>
                    </tr>
                    <tr v-if="ticket.FuelTankWeightKG">
                      <td>
                        Used Fuel Weight Adjustment
                      </td>
                      <td>{{ ticket.FuelTankWeightKG }}&nbsp;KG</td>
                    </tr>

                    <tr v-if="ticket.OutboundWeightKG">
                      <td>Weight2</td>
                      <td>{{ ticket.OutboundWeightKG }}&nbsp;KG</td>
                      <td>
                        {{
                          moment(ticket.OutboundDateTime).format(
                            "DD/MM/YYYY hh:mm"
                          )
                        }}
                      </td>
                      <td>{{ ticket.OutboundInstrument }}</td>
                      <td>{{ ticket.OutboundType }}</td>
                    </tr>

                    <tr>
                      <td style="font-weight:bold;">Nett Weight</td>
                      <td style="font-weight:bold;">
                        {{
                          (ticket.OutboundWeightKG
                            ? Math.abs(
                                ticket.InboundWeightKG - ticket.OutboundWeightKG
                              )
                            : ticket.InboundWeightKG -
                              (ticket.TareWeightKG - ticket.FuelTankWeightKG)
                          ).toFixed(2)
                        }}&nbsp; KG
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row v-if="ticket.QCDocuments && ticket.QCDocuments.length">
              <v-col cols="12" xs="12" md="12">
                <p class="text-h6">Attached QC Documents</p>
                <v-data-table
                  :headers="[
                    { text: 'Document Reference', value: 'qcNumber' },
                    { text: 'Tipping Zone', value: 'tippingZone' },
                    { text: 'Supplier', value: 'supplier.Name' },
                    { text: 'Supplier Site', value: 'supplierSite.Name' },
                    { text: 'Supplier Reference', value: 'supplierReference' },
                  ]"
                  :items="ticket.QCDocuments"
                  dense
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.qcNumber="{ item }">
                    QC{{ item.qcNumber.toString().padStart(6, "0") }}
                  </template>

                  <template v-slot:item.tippingZone="{ item }">
                    {{ item.intendedTippingZone.code }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row v-if="ticket.TransferNotes && ticket.TransferNotes.length">
              <v-col cols="12" xs="12" md="12">
                <p class="text-h6">Attached Transfer Notes</p>
                <v-data-table
                  :headers="[
                    { text: 'Document Reference', value: 'transferNoteNumber' },
                    { text: 'Source', value: 'sourceWarehouse' },
                    { text: 'Destination', value: 'destinationWarehouse' },
                    { text: 'Location', value: 'stocklocationcode' },
                    { text: 'Direction', value: 'transferDirection' },
                  ]"
                  :items="ticket.TransferNotes"
                  dense
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.transferNoteNumber="{ item }">
                    TN{{ item.transferNoteNumber.toString().padStart(6, "0") }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelForm" outlined> Cancel </v-btn>&nbsp;
          <v-btn color="success" @click="saveForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// This component allows the user to edit basic details such as the ticket reference, customer and site.

import SelectFunctions from "../select/SelectFunctions";
import moment from "moment";

export default {
  mounted() {
    this.setupForm();
  },

  methods: {
    setupForm() {
      this.lookupCompanies();
      this.lookupDrivers();
      this.lookupTractors();
      this.getFleetUnits();
    },

    editTicketInfo(paramTicket) {
      this.dialog = true;
      this.ticket = JSON.parse(JSON.stringify(paramTicket));
    },

    cancelForm() {
      this.dialog = false;
    },

    async saveForm() {
      if (!this.$refs.formEditTicket.validate()) {
        this.$notify({
          group: "global",
          title: "Validation Problem",
          text: "Please ensure that all required fields are completed",
          type: "info",
        });

        return;
      }

      if (
        !(await this.$root.$confirm("Save changes to ticket?", {
          title: "Save Changes",
          color: "primary",
        }))
      ) {
        return;
      }

      try {
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_ENDPOINT}tickets/closed/${this.ticket._id}/update`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.ticket),
            credentials: "include",
          }
        );

        let result = await response.json();
        if (!response.ok) {
          throw new Error("Invalid return from server : " + response.status);
        }

        this.$notify({
          group: "global",
          title: "Ticket Details Saved",
          text: "Ticket details have been saved",
          type: "success",
        });

        this.$emit("formSaved");
        this.dialog = false;
      } catch (e) {
        console.log("saveForm : caught error : ", e);
        this.$notify({
          group: "global",
          title: "Error Saving Ticket Details",
          text: "There was an error saving ticket details, please re-try",
          type: "error",
        });
      }
    },

    async lookupCompanies() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT +
          `tickets/previous/companies/list`,
        {
          credentials: "include",
        }
      );

      let result = await response.json();
      this.companyList = result;
    },

    async lookupDrivers() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + `tickets/previous/drivers/list`,
        {
          credentials: "include",
        }
      );

      let result = await response.json();
      this.driverList = result;
    },

    async lookupTractors() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + `tickets/previous/tractors/list`,
        {
          credentials: "include",
        }
      );

      let result = await response.json();
      this.tractorList = result;
    },

    async getFleetUnits() {
      // get our built in fleet units from the server:
      try {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + "transport/fleet",
          { credentials: "include" }
        );

        this.fleetUnits = await response.json();

        // console.log("getFleetUnits : fleetUnits : ", this.fleetUnits);

        // load in the tractor units from the pre-selects:
        let tractors = this.fleetUnits.filter(
          (record) => record.UnitType == "Tractor Unit"
        );
        for (let vehicle of tractors) {
          this.tractorList = this.tractorList.filter((record) => {
            return (
              record
                .toUpperCase()
                .replace(" ", "")
                .trim() !=
              vehicle.RegNumber.toUpperCase()
                .replace(" ", "")
                .trim()
            );
          });
          this.tractorList.push(vehicle.RegNumber);
        }

        // load in the trailer lists:
        let trailers = this.fleetUnits.filter(
          (record) => record.UnitType != "Tractor Unit"
        );
        for (let vehicle of trailers) {
          this.trailerList.push(vehicle.RegNumber);
        }
      } catch (e) {
        console.error("getFleetUnits : caught error : ", e);
      }
    },
  },

  computed: {
    moment: () => {
      return moment;
    },
  },

  data: () => ({
    dialog: false,
    ticket: null,
    companyList: [],
    driverList: [],
    tractorList: [],
    trailerList: [],
  }),
};
</script>
