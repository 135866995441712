<template>
  <div>
    <v-dialog v-model="dialog" max-width="1200" scrollable persistent>
      <v-card>
        <v-card-title class="primary white--text">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="onClose">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-card class="my-1 py-3">
            <v-form ref="formdetails" class="mx-2" lazy-validation>
              <table class="dialog-content-table">
                <tbody>
                  <tr v-if="systemLinkedToQCSystem">
                    <td class="text-left">QC / Document Reference:</td>
                    <td>
                      <v-text-field
                        v-model="ticket.DocumentRef"
                        clearable
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="documentSearching">
                    <!-- <td>Loading...</td> -->
                    <td colspan="2">
                      <v-progress-linear
                        indeterminate
                        color="primary"
                        height="25"
                        striped
                      >
                        Loading...
                      </v-progress-linear>
                    </td>
                  </tr>
                  <tr v-if="documentSearchResults.length">
                    <td></td>
                    <td colspan="1">
                      <v-data-table
                        dense
                        :headers="[
                          {
                            text: 'Document Reference',
                            align: 'left',
                            value: 'documentNumber',
                          },
                          { text: 'Status', align: 'left', value: 'status' },
                          {
                            text: 'Tipping Zone',
                            value: 'intendedTippingZone',
                          },
                          {
                            text: 'Supplier',
                            align: 'left',
                            value: 'supplier',
                          },
                          {
                            text: 'Site',
                            align: 'left',
                            value: 'site',
                          },
                          {
                            text: 'Expected/Requested Date',
                            align: 'left',
                            value: 'expectedDate',
                          },

                          { text: 'Driver', value: 'driver' },
                          { text: 'Vehicles', value: 'vehicles' },
                        ]"
                        :items="computedSearchResults"
                        disable-pagination
                        hide-default-footer
                        show-select
                        :single-select="singleSelect ? true : false"
                        item-key="documentNumber"
                        v-model="selectedDocuments"
                        item-class="rowClass"
                      >
                        <template
                          v-slot:item.data-table-select="{
                            item,
                            isSelected,
                            select,
                          }"
                        >
                          <v-simple-checkbox
                            :value="isSelected"
                            :readonly="item.readOnly"
                            :disabled="item.readOnly"
                            @input="select($event)"
                          ></v-simple-checkbox>
                        </template>

                        <template v-slot:item.expectedDate="{ value }">
                          {{ moment(value).format("DD/MM/YYYY") }}
                        </template>
                        <template v-slot:item.driver="{ item }">
                          <span v-if="item.driver">
                            {{ item.driver.Forename }}&nbsp;{{
                              item.driver.Surname
                            }}
                          </span>
                        </template>
                        <template v-slot:item.collectionSite="{ item }">
                          <strong>{{ item.collectionSite }}</strong>
                        </template>
                        <template v-slot:item.vehicles="{ item }">
                          <span
                            v-for="vehicle in item.vehicles"
                            :key="vehicle._id"
                          >
                            {{ vehicle.RegNumber }}&nbsp;{{
                              vehicle.UnitType
                            }}&nbsp;
                          </span>
                        </template>
                        <template v-slot:item.supplier="{ item }">
                          <span v-if="item.documentType === 'TRANSFER NOTE'">
                            {{ item.supplier }}
                          </span>
                          <span v-else>
                            {{ item.supplier.Name }}
                          </span>
                        </template>
                      </v-data-table>
                    </td>
                  </tr>
                  <tr v-if="selectedDocuments.length">
                    <td></td>
                    <td>
                      <v-card class="success mt-10" dark>
                        <v-card-title>
                          Selected Documents
                        </v-card-title>
                        <v-card-text dense>
                          <v-data-table
                            dense
                            :headers="[
                              {
                                text: 'Document Reference',
                                align: 'left',
                                value: 'documentNumber',
                              },
                              {
                                text: 'Tipping Zone',
                                value: 'intendedTippingZone',
                              },
                              { text: '', value: 'editTippingZone' },
                              {
                                text: 'Status',
                                align: 'left',
                                value: 'status',
                              },

                              {
                                text: 'Supplier',
                                align: 'left',
                                value: 'supplier',
                              },
                              { text: 'Driver', value: 'driver' },
                              { text: 'Vehicles', value: 'vehicles' },

                              { text: '', value: 'removeRecord' },
                            ]"
                            :items="selectedDocuments"
                            disable-pagination
                            hide-default-footer
                            item-key="_id"
                            class="transparent"
                          >
                            <template v-slot:item.expectedDate="{ value }">
                              {{ moment(value).format("DD/MM/YYYY") }}
                            </template>
                            <template v-slot:item.driver="{ item }">
                              <span v-if="item.driver">
                                {{ item.driver.Forename }}&nbsp;{{
                                  item.driver.Surname
                                }}
                              </span>
                            </template>
                            <template v-slot:item.vehicles="{ item }">
                              <span
                                v-for="vehicle in item.vehicles"
                                :key="vehicle._id"
                              >
                                {{ vehicle.RegNumber }}&nbsp;{{
                                  vehicle.UnitType
                                }}&nbsp;
                              </span>
                            </template>
                            <template v-slot:item.supplier="{ item }">
                              <span
                                v-if="item.documentType === 'TRANSFER NOTE'"
                              >
                                {{ item.supplier }}
                              </span>
                              <span v-else>
                                {{ item.supplier.Name }}
                              </span>
                            </template>

                            <template v-slot:item.removeRecord="{ item }">
                              <v-btn
                                class="error"
                                @click="removeSelectedDocumentRecord(item)"
                                small
                                v-if="!(ticket && ticket._id)"
                              >
                                Remove
                              </v-btn>
                            </template>
                            <template v-slot:item.editTippingZone="{ item }">
                              <v-btn
                                v-if="item.documentType != 'TRANSFER NOTE'"
                                color="primary"
                                xxxclass="mr-2"
                                @click="editTippingZoneValue(item)"
                                small
                              >
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Company Name:</td>
                    <td>
                      <!-- <v-text-field
                      v-model="ticket.CompanyName"
                      required
                      :rules="rules.CompanyName"
                    ></v-text-field> -->

                      <v-combobox
                        :items="companyList"
                        v-model="ticket.CompanyName"
                        :rules="rules.CompanyName"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Driver:</td>
                    <td>
                      <v-combobox
                        v-model="ticket.DriverName"
                        required
                        :rules="rules.DriverName"
                        :items="driverList"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Vehicle registration:</td>
                    <td>
                      <v-combobox
                        v-model="ticket.VehicleReg"
                        required
                        :rules="rules.VehicleReg"
                        :items="tractorList"
                        @change="handleTractorChange"
                        :hint="tractorTare ? tractorTare + ' KG' : ''"
                        :persistent-hint="tractorTare ? true : false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Trailer Number:</td>
                    <td>
                      <!-- <v-text-field
                      v-model="ticket.TrailerNumber"
                      :rules="rules.TrailerNumber"
                    ></v-text-field> -->

                      <v-combobox
                        v-model="ticket.TrailerNumber"
                        :rules="rules.TrailerNumber"
                        :items="trailerList"
                        @change="handleTrailerChange"
                        :hint="trailerTare ? trailerTare + ' KG' : ''"
                        :persistent-hint="trailerTare ? true : false"
                      />
                    </td>
                  </tr>
                  <!-- Temporarily disabled the fuel tank reading so that the user can't deduct the fuel weight -->
                  <tr
                    v-if="
                      computedIsCompanyVehicle && ticket.InboundType == 'LADEN'
                    "
                  >
                    <td class="text-left">Fuel Tank Reading</td>
                    <td>
                      <v-autocomplete
                        :items="fuelTankOptions"
                        v-model="ticket.FuelTankWeightKG"
                        :persistent-hint="true"
                        :rules="[
                          (v) =>
                            v != null || 'Please choose a Fuel Tank Reading',
                        ]"
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <!-- <tr
                    v-if="
                      tractorTare &&
                        trailerTare &&
                        singleSelect !== true &&
                        !ticket.TareWeightKG &&
                        ticket.InboundType &&
                        ticket.InboundType.toUpperCase() == 'LADEN' &&
                        !this.twinPotDocumentSelected
                    "
                  >
                    <td></td>
                    <td>
                      <v-alert class="success" dark
                        >Tractor and Trailer Tare are available &nbsp;
                        <v-spacer />
                        <v-btn outlined @click="applyTareWeightFromVehicles"
                          >Apply</v-btn
                        >
                      </v-alert>
                    </td>
                  </tr> -->
                  <tr v-if="!systemLinkedToQCSystem">
                    <td class="text-left">QC / Document Reference:</td>
                    <td>
                      <v-text-field v-model="ticket.DocumentRef"></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-form>
          </v-card>
          <v-card class="mb-1 mt-4 py-3">
            <v-card-text>
              <v-form ref="forminbound" class="mx-2" lazy-validation>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <span class="text-subtitle-1">Weight 1</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" md="2">
                    <v-select
                      :solo="isNew"
                      label="Type"
                      :disabled="!isNew"
                      :items="weighingType"
                      v-model="ticket.InboundType"
                      :rules="rules.InboundType"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-select
                      :solo="isNew"
                      label="Weighbridge"
                      :disabled="!isNew"
                      :items="weighbridges"
                      item-text="name"
                      item-value="code"
                      v-model="ticket.InboundInstrument"
                      :rules="rules.InboundInstrument"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      :solo="isNew"
                      :disabled="!isNew"
                      :readonly="username !== 'SELECT'"
                      type="number"
                      suffix="kgs"
                      v-model="ticket.InboundWeightKG"
                      :rules="rules.InboundWeightKG"
                    />
                  </v-col>

                  <v-col cols="12" xs="12" md="2">
                    <v-btn
                      class="primary"
                      @click="getWeighbridgeResults('inbound')"
                      large
                      :disabled="!isNew"
                      >Capture Weight</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row v-if="computedIsCompanyVehicle">
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      placeholder="Tare Weight (KG)"
                      label="Tare Weight (KG)"
                      type="number"
                      @change="onTareChange"
                      v-model="ticket.TareWeightKG"
                      outlined
                      xxxhint="Entering Tare-weight will close the ticket as no second reading required"
                      xxxpersistent-hint
                      :xxxreadonly="twinPotDocumentSelected"
                      :readonly="username !== 'SELECT'"
                      outline
                      clearable
                    >
                      <template v-slot:append>KG</template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      xxv-if="ticket.TareWeightKG"
                      outlined
                      placeholder="Fuel Usage Adjustment (KG)"
                      label="Fuel Usage Adjustment (KG)"
                      v-model="ticket.FuelTankWeightKG"
                      :readonly="username !== 'SELECT'"
                    >
                      <template v-slot:append>KG</template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      outlined
                      placeholder="Nett Weight (KG)"
                      label="Nett Weight (KG)"
                      v-model="computedNettWeight"
                      :readonly="username !== 'SELECT'"
                    >
                      <template v-slot:append>KG</template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-text-field
                      :solo="isNew"
                      :disabled="!isNew"
                      type="text"
                      label="Comments"
                      v-model="ticket.InboundComment"
                      :rules="rules.InboundComment"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" md="12"> </v-col>
                </v-row>
              </v-form>
              <v-form ref="formoutbound" class="mx-2" lazy-validation>
                <v-row v-if="!selectedTicket.TareWeightKG">
                  <v-col cols="12" xs="12" md="12">
                    <span class="text-subtitle-1">Weight 2</span>
                  </v-col>
                </v-row>

                <v-row v-if="!selectedTicket.TareWeightKG">
                  <v-col cols="12" xs="12" md="2">
                    <v-select
                      :solo="!isNew && !isClosed"
                      label="Type"
                      :disabled="isNew || isClosed"
                      :items="weighingType"
                      v-model="ticket.OutboundType"
                      :rules="rules.OutboundType"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" xs="12" md="4">
                    <v-select
                      :solo="!isNew && !isClosed"
                      label="Weighbridge"
                      :disabled="isNew || isClosed"
                      :items="weighbridges"
                      item-text="name"
                      item-value="code"
                      v-model="ticket.OutboundInstrument"
                      :rules="rules.OutboundInstrument"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      :solo="!isNew && !isClosed"
                      :disabled="isNew || isClosed"
                      :readonly="username !== 'SELECT'"
                      type="number"
                      suffix="kgs"
                      v-model="ticket.OutboundWeightKG"
                      :rules="rules.OutboundWeightKG"
                    />
                  </v-col>

                  <v-col cols="12" xs="12" md="3">
                    <v-btn
                      class="primary"
                      @click="getWeighbridgeResults('outbound')"
                      :disabled="isNew || isClosed"
                      large
                    >
                      Capture Weight
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="!selectedTicket.TareWeightKG">
                  <v-col cols="12" xs="12" md="11">
                    <v-text-field
                      :solo="!isNew && !isClosed"
                      :disabled="isNew || isClosed"
                      hide-details
                      type="text"
                      label="Comments"
                      v-model="ticket.OutboundComment"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onPressPrintTicket"
            :disabled="isNew || selectedTicket.Cancelled"
            icon
            outlined
            class="mr-4"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn
            color="success"
            @click="onPressSave"
            :disabled="isClosed || selectedTicket.Cancelled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <error-snackbar
        :snackbar="response.status"
        :text="response.text"
        :color="response.color"
        @close="onCloseSnackbar"
      >
      </error-snackbar>

      <v-overlay :value="waitingForInstrument">
        <v-progress-circular v-if="waitingForInstrument" indeterminate />
      </v-overlay>
    </v-dialog>

    <v-dialog v-model="showTippingZoneChange">
      <v-card>
        <v-card-title class="primary white--text">
          Change Intended Tipping Zone
        </v-card-title>
        <v-card-text class="pt-2">
          <v-select
            label="Location"
            v-model="preferredZone"
            :items="processZones"
            item-value="_id"
            item-text="code"
            return-object
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showTippingZoneChange = false">Cancel</v-btn>
          <v-btn color="primary" @click="saveTippingChange">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SelectFunctions from "../select/SelectFunctions";
import ErrorSnackbar from "./ErrorSnackbar.vue";
import QCSystemLinkHelper from "@/classes/QCSystemLinkHelper";

import moment from "moment";

import _ from "lodash";

//Import UUID v4
import { v4 as uuidv4 } from "uuid";

export default {
  name: "WeighingDialog",
  components: { ErrorSnackbar },
  data() {
    return {
      showTippingZoneChange: false,
      processZones: [],
      ticket: { QCDocuments: [], VehicleReg: "", TrailerNumber: "" },
      weighingType: ["LADEN", "UNLADEN"],
      weighbridges: [],
      rules: {
        CompanyName: [
          (v) => v.length <= 50 || "Max 50 characters",
          (v) => v.length > 0 || "This field is required.",
        ],
        DriverName: [
          //trim prevents blank spaces being accepted
          (v) => v.length <= 50 || "Max 50 characters",
          (v) => v.trim().length > 0 || "This field is required."
        ],
        VehicleReg: [
          (v) => v.length <= 50 || "Max 50 characters",
          (v) => v.trim().length > 0 || "This field is required.",
        ],
        TrailerNumber: [(v) => v.length <= 50 || "Max 50 characters"],

        InboundType: [(v) => v.length > 0 || "This field is required."],
        InboundInstrument: [(v) => v.length > 0 || "This field is required."],
        InboundWeightKG: [
          (v) => v > 0 || "Click Capture Weight to capture a weight reading.",
        ],
        InboundComment: [(v) => v.length <= 50 || "Max 50 characters."],

        OutboundType: [(v) => v.length > 0 || "This field is required."],
        OutboundInstrument: [(v) => v.length > 0 || "This field is required."],
        OutboundWeightKG: [
          (v) => v > 0 || "Click Capture Weight to capture a weight reading.",
        ],
        OutboundComment: [(v) => v.length <= 50 || "Max 50 characters."],
      },
      response: {
        status: false,
        text: "",
        color: "",
      },
      waitingForInstrument: false,
      readingAttempts: 0,
      readingPollTimerID: null,
      changeintendedTippingZone: {},

      // lookups for comboboxes:
      companyList: [],
      driverList: [],
      tractorList: [],
      trailerList: [],
      preferredZone: null,

      username: "",

      fleetUnits: [], // this is our built in fleet unit list
      tractorTare: 0.0,
      trailerTare: 0.0,

      systemLinkedToQCSystem: false,

      // Search results for searching for documents from
      // the main system.

      documentSearchResults: [],
      transferSearchResults: [],
      documentSearching: false,
      selectedDocuments: [],
      computedSelectedDocuments: [],

      // Options for Fuel Tank Readings:
      // Based on a Full tank being 490KG
      fuelTankOptionsOld: [
        { text: "N/A", value: 0.0 },
        { text: "Empty", value: 0.0000001 },
        { text: "Quarter", value: 123 },
        { text: "Half", value: 245 },
        { text: "Three-Quarters", value: 368 },
        { text: "Full", value: 490 },
      ],

      // Based on a full tank of fuel weight
      // of 431.2 and a reserve tank of 10L (8.8KG)

      fuelTankOptions: [
        { text: "Full", value: 0.0 },
        { text: "Three-Quarters", value: 107.8 },
        { text: "Half", value: 215.6 },
        { text: "Quarter", value: 323.4 },
        { text: "Reserve", value: 422.4 },
      ],

      debouncedSearch: _.debounce(this.searchForMainSystemDocuments, 500),

      lastRun: null,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selectedTicket: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedSearchResults() {
      return this.documentSearchResults.map((record) => {
        record.readOnly = this.isDocumentReadOnly(record);
        record.rowClass = "";
        if (record.readOnly) {
          record.rowClass = "blue-grey";
        }

        return record;
      });
    },

    title() {
      return this.ticket.Status === "" ? "New ticket" : "Edit ticket";
    },
    isNew() {
      return this.ticket.Status !== "OPEN" && this.ticket.Status !== "CLOSED";
    },
    isClosed() {
      return this.ticket.Status === "CLOSED";
    },
    moment: () => {
      return moment;
    },

    computedCanUseDriver() {
      // Checks to see if the same driver is assigned
      // to the QC documents selected

      if (!this.selectedDocuments.length) {
        return false;
      }
      if (
        !(
          this.selectedDocuments[0].driver &&
          this.selectedDocuments[0].driver._id
        )
      ) {
        return false;
      }
      let currentDriver = this.selectedDocuments[0].driver._id;
      for (let document of this.selectedDocuments) {
        if (document.driver._id != currentDriver) {
          return false;
        }
      }

      return true;
    },

    computedIsCompanyVehicle() {
      // get the reg number that we've entered

      let regNumber = this.ticket.VehicleReg;

      if (!regNumber) {
        return false;
      }

      let check = this.fleetUnits.find(
        (record) =>
          record.RegNumber &&
          record.RegNumber.toUpperCase().trim() ==
            regNumber.toUpperCase().trim() &&
          record.UnitType &&
          record.UnitType.toUpperCase() == "TRACTOR UNIT" &&
          record.tareWeightKG
      );
      if (check) {
        // Okay tractor is company vehicle - is the trailer?
        let trailerNumber = this.ticket.TrailerNumber;
        if (trailerNumber) {
          let checkTrailer = this.fleetUnits.find(
            (record) =>
              record.RegNumber &&
              record.RegNumber.toUpperCase().trim() ==
                trailerNumber.toUpperCase().trim() &&
              record.UnitType &&
              record.UnitType.toUpperCase() != "TRACTOR UNIT" &&
              !record.twinPot &&
              record.tareWeightKG
          );
          if (checkTrailer) {
            return true;
          }
        }
      }

      return false;
    },

    computedCanUseTractor() {
      // Checks to see if the same tractor unit can be used by the system
      // based on the linked document(s) selected

      if (!this.selectedDocuments.length) {
        return false;
      }
      let currentTractor = this.selectedDocuments[0].vehicles.find(
        (record) => record.UnitType.toUpperCase() == "TRACTOR UNIT"
      );
      console.log(currentTractor, "CURRENT TRACTOR");
      if (!currentTractor) {
        return false;
      }

      for (let document of this.selectedDocuments) {
        let tractor = document.vehicles.find(
          (record) => record.UnitType.toUpperCase() == "TRACTOR UNIT"
        );
        if (!tractor) {
          return false;
        }

        if (tractor._id != currentTractor._id) {
          return false;
        }
      }

      return true;
    },
    computedCanUseTrailer() {
      // Checks to see if the same trailer number can be used for the QC Document
      console.log(
        "computedCanUseTrailer : selected documents : ",
        this.selectedDocuments
      );

      if (!this.selectedDocuments.length) {
        return false;
      }

      let currentTrailer = this.selectedDocuments[0].vehicles.find(
        (record) => record.UnitType.toUpperCase() != "TRACTOR UNIT"
      );

      if (!currentTrailer) {
        return false;
      }

      for (let document of this.selectedDocuments) {
        let trailer = document.vehicles.find(
          (record) => record.UnitType.toUpperCase() != "TRACTOR UNIT"
        );
        if (!trailer) {
          return false;
        }

        if (trailer._id != currentTrailer._id) {
          return false;
        }
      }

      return true;
    },

    computedCanUseCompany() {
      // Checks to see if document is for the same company, if so , can automatically fill in the
      // company name.
      if (!this.selectedDocuments.length) {
        return false;
      }

      if (this.selectedDocuments && this.selectedDocuments.length) {
        console.log("There is a lenght");
        let currentCompany = this.selectedDocuments[0].supplier;
        console.log(currentCompany, "CURRENT COMPANY");
        if (!currentCompany) {
          console.log("NO COMPANY");
          return false;
        }
        for (let document of this.selectedDocuments) {
          console.log(
            currentCompany._id,
            document.supplier._id,
            "COMPANY DOCUMENT"
          );
          if (currentCompany._id != document.supplier._id) {
            console.log("1");
            return false;
          }
          if (currentCompany._id == document.supplier._id) {
            console.log("2");
            return true;
          }
        }
      }

      return true;
    },

    twinPotDocumentSelected() {
      let returnValue = false;
      for (let document of this.selectedDocuments) {
        if (this.twinPotCheck(document)) {
          returnValue = true;
        } // end if twinPotCheck
      } // end of for loop

      return returnValue;
    },

    computedNettWeight() {
      try {
        if (this.ticket.InboundWeightKG) {
          return Number(
            (
              this.ticket.InboundWeightKG -
              (this.ticket.TareWeightKG - this.ticket.FuelTankWeightKG)
            ).toFixed(2)
          );
        }
      } catch (e) {
        console.log("Error in computedNettWeight", e);
        return 0.0;
      }

      return 0.0;
    },
  },

  async mounted() {
    this.loadProcessZones();
    // this.searchForMainSystemTransferNotes();
    this.weighbridges = JSON.parse(process.env.VUE_APP_WEIGHBRIDGE_INSTRUMENTS);
    // lookup pre-selects based on previous weighbridge tickets
    this.lookupPreSelects();
    this.username = await SelectFunctions.getUsername();
    this.username = this.username.toUpperCase();

    this.checkSystemLinkedToQCSystem();
    this.singleSelect = false;
  },

  beforeDestroy() {
    if (this.readingPollTimerID) {
      clearInterval(this.readingPollTimerID);
    }
  },

  methods: {
    async loadProcessZones() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + `qcdocuments/processzones`,
        {
          credentials: "include",
        }
      );

      if (response && response.ok) {
        this.processZones = await response.json();
      }
    },

    async editTippingZoneValue(item) {
      this.changeintendedTippingZone = _.clone(item);
      this.showTippingZoneChange = true;
    },
    async updateTippingZoneValue() {
      let payload = this.changeintendedTippingZone;
      payload.intendedTippingZone = this.preferredZone;
      delete payload.readOnly;
      delete payload.rowClass;
      delete payload.source;
      delete payload.site;
      delete payload.documentType;
      delete payload.documentNumber;
      console.log(payload, "MY PAYLOAD");
      try {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + `qcdocuments/editTippingZone`,
          {
            credentials: "include",
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify(payload),
          }
        );

        if (!(response && response.ok)) {
          throw "Invalid return from server " + response.status;
        } else {
          this.$notify({
            group: "global",
            title: "",
            text: "Tipping zone updated successfully",
            type: "success",
          });
          this.showTippingZoneChange = false;
          this.searchForMainSystemDocuments(this.ticket.DocumentRef);
          let foundIndex = this.selectedDocuments.findIndex(
            (x) => x.qcNumber == payload.qcNumber
          );
          this.selectedDocuments[foundIndex].intendedTippingZone =
            payload.intendedTippingZone.name;
        }
      } catch (e) {
        console.log("updateTippingZone error", e);
        this.$notify({
          group: "global",
          title: "Error",
          text:
            "There was a problem updating the tipping zone, please try again",
          type: "error",
        });
      }
    },
    saveTippingChange() {
      this.updateTippingZoneValue();
    },

    twinPotCheck(document) {
      if (document.vehicles && document.vehicles.length) {
        for (let vehicle of document.vehicles) {
          let check = this.fleetUnits.find(
            (record) =>
              record.RegNumber &&
              record.RegNumber.toUpperCase().trim() ==
                vehicle.RegNumber.toUpperCase().trim() &&
              record.twinPot == true
          );

          if (check) {
            return true;
          }
        }
      }
    },

    onClose() {
      this.$emit("onClose");
    },

    onPressPrintTicket() {
      var sURL =
        process.env.VUE_APP_BACKEND_ENDPOINT +
        "tickets/" +
        this.ticket.id +
        "/print";
      window.open(sURL);
    },

    onPressSave() {
      var bValid = this.onValidate();

      // this.ticket.QCDocuments = this.selectedDocuments;
      let filteredQCs = this.selectedDocuments.filter((document) => {
        return document.documentType == "QC DOCUMENT";
      });

      let filteredTransfers = this.selectedDocuments.filter((document) => {
        return document.documentType == "TRANSFER NOTE";
      });

      this.ticket.QCDocuments = filteredQCs;
      this.ticket.TransferNotes = filteredTransfers;

      // build up the document reference for save:
      if (this.selectedDocuments.length) {
        this.ticket.DocumentRef = this.createDocumentRefFromSelectedDocuments();
      }

      if (bValid) {
        var sEvent =
          this.ticket.Status === ""
            ? "onSaveNewTicket"
            : this.ticket.Status === "OPEN"
            ? "onUpdateTicket"
            : "";
        this.$emit(sEvent, this.ticket);
      } else {
        this.$notify({
          group: "global",
          type: "error",
          title: "Form Not Valid",
          text: "Please ensure all fields are entered correctly",
        });
      }
    },

    onValidate() {
      //NEW
      if (this.isNew) {
        return (
          this.$refs.formdetails.validate() && this.$refs.forminbound.validate()
        );
      }
      //OPEN
      else if (!this.isClosed) {
        return this.$refs.formoutbound.validate();
      }

      return false;
    },

    isFieldValid(sFieldName) {
      var bValid = true;
      var sFieldValue = this.ticket[sFieldName];
      var aRules = this.rules[sFieldName];

      for (var oRule of aRules) {
        bValid = bValid && oRule(sFieldValue) == true;
      }

      return bValid;
    },

    async getWeighbridgeResults(sType) {
      if (sType === "inbound") {
        if (!(this.ticket.InboundType && this.ticket.InboundInstrument)) {
          this.$notify({
            group: "global",
            type: "error",
            title: "No Weighbridge Selected",
            text:
              "Please choose a reading type and a weighbridge for the reading",
          });
          return false;
        }
        var sReadURL = this.getWeighbridgeAddress(
          this.ticket.InboundInstrument
        );
        this.readWeight(sType, sReadURL);
      } else if (sType === "outbound") {
        if (!(this.ticket.OutboundType && this.ticket.OutboundInstrument)) {
          this.$notify({
            group: "global",
            type: "error",
            title: "No Weighbridge Selected",
            text:
              "Please choose a reading type and a weighbridge for the reading",
          });
          return false;
        }
        var sReadURL = this.getWeighbridgeAddress(
          this.ticket.OutboundInstrument
        );
        this.readWeight(sType, sReadURL);
      }
    },

    setWeighbridgeResults(sType, oWeight) {
      if (sType === "inbound") {
        this.$notify({
          group: "global",
          type: "success",
          title: "Weight Captured",
          text: "The weighbridge returned the weight reading successfully",
        });

        // Have the weight recorded as a arbitary min value for testing purposes.
        // We'll need to take this out once we are live.
        this.ticket.InboundWeightKG = oWeight.lastReading || 0.0;
        this.ticket.InboundSerial = "SYSTEM";
      } else if (sType === "outbound") {
        this.$notify({
          group: "global",
          type: "success",
          title: "Weight Captured",
          text: "The weighbridge returned the weight reading successfully",
        });

        this.ticket.OutboundWeightKG = oWeight.lastReading || 0.0;
        this.ticket.OutboundSerial = oWeight.serialNumber;
      }
    },

    getWeighbridgeAddress(sCode) {
      var aInstruments = this.weighbridges;
      var oBridge = aInstruments.find(function(o) {
        return o.code === sCode;
      });

      return oBridge ? oBridge.address + "/LAST_READING" : "";
    },

    async pollInstrument(sType, sReadURL) {
      console.log("pollInstrument : sType : ", sType);
      console.log("pollInstrument : sReadURL : ", sReadURL);
      this.setBusyIndicator(true);
      try {
        let response = await SelectFunctions.fetch(sReadURL, {});

        let result = await response.json();

        if (result.lastReading != null && result.lastReading != undefined) {
          this.setBusyIndicator(false);
          clearInterval(this.readingPollTimerID);
          this.setWeighbridgeResults(sType, result);

          console.log(
            "pollInstrument : using last reading to record magscale weight"
          );
        }

        // if (result.status == "OK")
        // {
        //   this.setBusyIndicator(false);
        //   clearInterval(this.readingPollTimerID);
        //   this.setWeighbridgeResults(sType, result);

        //   return result;
        // } else if (result.status == "TIME_OUT")
        // {
        //   this.readingAttempts++;

        //   if (this.readingAttempts > 9)
        //   {
        //     this.setBusyIndicator(false);
        //     clearInterval(this.readingPollTimerID);

        //     this.$notify(
        //       {
        //         group: 'global',
        //         type: 'error',
        //         title: 'Problem Reading Weight',
        //         text: 'The instrument did not respond in a timely manner, please re-try'
        //       }
        //     );

        //     return 0;
        //   }
        // } else if (result.status == "BELOW_THRESHOLD")
        // {
        //   this.setBusyIndicator(false);
        //   clearInterval(this.readingPollTimerID);

        //   this.$notify(
        //     {
        //       group: 'global',
        //       type: 'error',
        //       title: 'Problem Reading Weight',
        //       text: 'The instrument indicates that the weight is below the threshold for a valid reading'
        //     }
        //   );
        //   return 0;
        // } else if (result.status == "ABOVE_THRESHOLD")
        // {
        //   this.setBusyIndicator(false);
        //   clearInterval(this.readingPollTimerID);

        //   this.$notify(
        //     {
        //       group: 'global',
        //       type: 'error',
        //       title: 'Problem Reading Weight',
        //       text: 'The instrument indicates that the weight is above the threshold for a valid reading'
        //     }
        //   );

        //   return 0;
        // }
        // else if (result.status == "NEGATIVE_WEIGHT")
        // {
        //   this.setBusyIndicator(false);
        //   clearInterval(this.readingPollTimerID);

        //   this.$notify(
        //     {
        //       group: 'global',
        //       type: 'error',
        //       title: 'Negative Weight',
        //       text: 'The instrument is reporting a NEGATIVE reading.  Is the instrument fully calibrated and set zero correctly?'
        //     }
        //   );
        // }
        // else
        // {
        //   this.setBusyIndicator(false);
        //   clearInterval(this.readingPollTimerID);

        //   this.$notify(
        //     {
        //       group: 'global',
        //       type: 'error',
        //       title: 'Problem Reading Weight',
        //       text: 'There was a general problem reading the data from the weighbridge'
        //     }
        //   );

        // }
      } catch (e) {
        this.setBusyIndicator(false);
        clearInterval(this.readingPollTimerID);

        console.log("Caught error from server : ", e);

        this.$notify({
          group: "global",
          type: "error",
          title: "Problem Reading Weight",
          text:
            "There was a general problem reading the data from the weighbridge",
        });

        return 0;
      }
    },

    async readWeight(sType, sReadURL) {
      this.readingAttempts = 0;
      this.pollInstrument(sType, sReadURL);
      this.readingPollTimerID = setInterval(() => {
        this.pollInstrument(sType, sReadURL);
      }, 3000);
    },

    setBusyIndicator(bBusy) {
      if (bBusy) {
        this.$root.showProcessing();
      } else {
        this.$root.hideProcessing();
      }
    },

    onOpenSnackbar(sText, sColor) {
      this.response.status = true;
      this.response.text = sText;
      this.response.color = sColor;
    },

    onCloseSnackbar: function() {
      this.response.status = false;
    },

    async lookupPreSelects() {
      this.lookupCompanies();
      this.lookupDrivers();
      await this.lookupTractors();
      await this.getFleetUnits();
    },

    async lookupCompanies() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT +
          `tickets/previous/companies/list`,
        {
          credentials: "include",
        }
      );

      let result = await response.json();
      this.companyList = result;
    },

    async lookupDrivers() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + `tickets/previous/drivers/list`,
        {
          credentials: "include",
        }
      );

      let result = await response.json();
      this.driverList = result;
    },

    async lookupTractors() {
      let response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + `tickets/previous/tractors/list`,
        {
          credentials: "include",
        }
      );

      let result = await response.json();
      this.tractorList = result;
    },

    onTareChange(tareWeightKG) {
      console.log("onTareChange : tareWeightKG : ", tareWeightKG);
    },

    async getFleetUnits() {
      // get our built in fleet units from the server:
      try {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + "transport/fleet",
          { credentials: "include" }
        );

        this.fleetUnits = await response.json();

        // console.log("getFleetUnits : fleetUnits : ", this.fleetUnits);

        // load in the tractor units from the pre-selects:
        let tractors = this.fleetUnits.filter(
          (record) =>
            record.UnitType == "Tractor Unit" &&
            record.status == "ACTIVE" &&
            record.RegNumber
        );
        for (let vehicle of tractors) {
          this.tractorList = this.tractorList.filter((record) => {
            return (
              record
                .toUpperCase()
                .replace(" ", "")
                .trim() !=
              vehicle.RegNumber.toUpperCase()
                .replace(" ", "")
                .trim()
            );
          });
          this.tractorList.push(vehicle.RegNumber);
        }

        // load in the trailer lists:
        let trailers = this.fleetUnits.filter(
          (record) =>
            record.UnitType != "Tractor Unit" &&
            record.status == "ACTIVE" &&
            record.RegNumber
        );
        for (let vehicle of trailers) {
          this.trailerList.push(vehicle.RegNumber);
        }
      } catch (e) {
        console.error("getFleetUnits : caught error : ", e);
      }
    },

    handleTractorChange(value) {
      // check to see if the tractor unit is in our list and if we have a tare weight:
      // check to see if this reg is in our fleet unit:

      this.tractorTare = 0.0;

      console.log("handleTractorChange : value : ", value);

      if (!value) {
        return false;
      }

      console.log("handleTractorChange : method fired : ", value);

      let check = this.fleetUnits.find((record) => {
        console.log("handleTractorChange : record : ", record);

        return (
          record.RegNumber &&
          record.RegNumber.trim().toUpperCase() == value.trim().toUpperCase() &&
          record.UnitType &&
          record.UnitType.toUpperCase() == "TRACTOR UNIT" &&
          record.tareWeightKG &&
          record.status == "ACTIVE"
        );
      });

      if (check && check.tareWeightKG) {
        this.tractorTare = check.tareWeightKG;
      } else {
        this.tractorTare = 0.0;
      }

      this.applyTareWeightFromVehicles();
    },

    handleTrailerChange(value) {
      // check to see if the tractor unit is in our list and if we have a tare weight:
      // check to see if this reg is in our fleet unit:

      this.trailerTare = 0.0;

      if (!value) {
        return false;
      }

      console.log("handleTrailerChange : method fired : ", value);

      let check = this.fleetUnits.find((record) => {
        return (
          record.RegNumber &&
          record.RegNumber.trim().toUpperCase() == value.trim().toUpperCase() &&
          record.UnitType &&
          record.UnitType.toUpperCase() != "TRACTOR UNIT" &&
          !record.twinPot &&
          record.tareWeightKG &&
          record.status == "ACTIVE"
        );
      });
      if (check && check.tareWeightKG) {
        this.trailerTare = check.tareWeightKG;
      } else {
        this.trailerTare = 0.0;
      }

      // WHY ARE WE DOING THIS??????
      // // change the selected QC documents so that they
      // // now have this vehicle selected
      // this.selectedDocuments.forEach((doc) => {
      //   doc.vehicles.forEach((vehicle) => {
      //     console.log("handleTrailerChange : vehicle : ", vehicle);
      //     if (vehicle.UnitType != "Tractor Unit") {
      //       console.log("handleTrailerChange : vehicle : ", vehicle);
      //       vehicle.RegNumber = value;
      //     }
      //   });
      // });

      this.applyTareWeightFromVehicles();
    },

    applyTareWeightFromVehicles() {
      if (
        this.tractorTare &&
        this.trailerTare &&
        this.singleSelect !== true &&
        this.ticket.InboundType &&
        this.ticket.InboundType.toUpperCase() == "LADEN" &&
        !this.twinPotDocumentSelected
      ) {
        this.ticket.TareWeightKG =
          Number(this.tractorTare) + Number(this.trailerTare);

        return;
      }

      this.ticket.TareWeightKG = 0.0;
      // If don't have a tare weight then we can't really
      // have a fuel deduction reading either

      this.ticket.FuelTankWeightKG = null;
      this.$forceUpdate();
    },

    editTicketCheckTare() {
      // double check's registrations to see if we can apply a tare weight

      this.handleTractorChange(this.ticket.VehicleReg);
      this.handleTrailerChange(this.ticket.TrailerNumber);
    },

    async checkSystemLinkedToQCSystem() {
      let helper = new QCSystemLinkHelper();
      this.systemLinkedToQCSystem = await helper.isSystemLinked();
    },

    async searchForMainSystemDocuments(searchText) {
      let lastRun = uuidv4();
      this.lastRun = lastRun;
      this.documentSearching = true;
      try {
        // QUERY FOR TRANSFER NOTES
        let transferNoteResponse = await fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + "transfernotes/receive/search",
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              searchText: searchText,
            }),
          }
        );

        // QUERY FOR QC DOCUMENTS
        let response = await fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + "qcdocuments/receive/search",
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              searchText: searchText,
            }),
          }
        );
        let transferNoteResult = await transferNoteResponse.json();

        transferNoteResult = transferNoteResult.map((item) => {
          let returnValue = {};
          returnValue._id = item._id;
          returnValue.source = item.sourceWarehouse.warehouseCode;
          returnValue.updated_at = item.updated_at;
          returnValue.uploaded_at = item.uploaded_at;
          returnValue.transferNoteNumber = item.transferNoteNumber;
          returnValue.documentType = "TRANSFER NOTE";
          returnValue.documentNumber =
            "TR" + item.transferNoteNumber.toString().padStart(6, "0");
          returnValue.status = item.status;
          returnValue.site = item.destinationWarehouse.warehouseCode;
          returnValue.expectedDate = item.requestedDate;
          returnValue.driver = item.driver;
          returnValue.vehicles = item.vehicles;
          returnValue.intendedTippingZone = "";
          returnValue.weights = item.weights;
          returnValue.transferredDate = item.transferredDate;
          returnValue.transferManifest = item.transferManifest;
          returnValue.transferDirection = item.transferDirection;
          returnValue.thirdPartyHaulier = item.thirdPartyHaulier;
          returnValue.testResults = item.testResults;
          returnValue.additionalTestResults = item.additionalTestResults;
          returnValue.cancellationReason = item.cancellationReason;
          returnValue.confirmedDate = item.confirmedDate;
          returnValue.createdBy = item.createdBy;
          returnValue.destinationWarehouse =
            item.destinationWarehouse.warehouseCode;
          returnValue.narrative = item.narrative;
          returnValue.requestedDate = item.requestedDate;
          returnValue.sourceWarehouse = item.sourceWarehouse.warehouseCode;
          returnValue.supplier = item.sourceWarehouse.warehouseCode;
          return returnValue;
        });
        let result = await response.json();

        result = result.map((item) => {
          let returnValue = {};
          returnValue._id = item._id;
          returnValue.source = item.supplier.Name;
          returnValue.RouteData = item.RouteData;
          returnValue.outboundContainers = item.outboundContainers;
          returnValue.packaging = item.packaging;
          returnValue.postedToStock = item.postedToStock;
          returnValue.pickUpContainerQty = item.pickUpContainerQty;
          returnValue.outboundPackaging = item.outboundPackaging;
          returnValue.outboundSigneeName = item.outboundSigneeName;
          returnValue.inboundSignature = item.inboundSignature;
          returnValue.inboundSigneeName = item.inboundSigneeName;
          returnValue.outboundSignature = item.outboundSignature;
          returnValue.SageGRNNumber = item.SageGRNNumber;
          returnValue.SageGRNPostedDate = item.SageGRNPostedDate;
          returnValue.SagePurchaseOrderNumber = item.SagePurchaseOrderNumber;
          returnValue.SambaIgnore = item.SambaIgnore;
          returnValue.SambaPOGenerated = item.SambaPOGenerated;
          returnValue.assumedProcessingDate = item.assumedProcessingDate;
          returnValue.cancellationReason = item.cancellationReason;
          returnValue.SambaPOGeneratedDateTime = item.SambaPOGeneratedDateTime;
          returnValue.additionalTestResults = item.additionalTestResults;
          returnValue.collectionDetails = item.collectionDetails;
          returnValue.arrivedDate = item.arrivedDate;
          returnValue.awaitingUpload = item.awaitingUpload;
          returnValue.createdBy = item.createdBy;
          returnValue.dropOffContainerQty = item.dropOffContainerQty;
          returnValue.contract = item.contract ? item.contract : {};
          returnValue.inboundPackagingCount = item.inboundPackagingCount;
          returnValue.containers = item.containers;
          returnValue.SagePurchaseOrderPostedDate =
            item.SagePurchaseOrderPostedDate;
          returnValue.qcNumber = item.qcNumber;
          returnValue.narrative = item.narrative;
          returnValue.inboundContainerCount = item.inboundContainerCount;
          returnValue.supplierSite = item.supplierSite;
          returnValue.documentType = "QC DOCUMENT";
          returnValue.documentNumber =
            "QC" + item.qcNumber.toString().padStart(6, "0");
          returnValue.status = item.status;
          returnValue.requestedDate = item.requestedDate;
          returnValue.stockMovements = item.stockMovements;
          returnValue.inboundSigneeName = item.inboundSigneeName;
          returnValue.supplier = item.supplier;
          returnValue.supplierReference = item.supplierReference;
          returnValue.testResults = item.testResults;
          returnValue.thirdPartyHaulier = item.thirdPartyHaulier;
          returnValue.unProcessableWaste = item.unProcessableWaste;
          returnValue.updated_at = item.updated_at;
          returnValue.uploaded_at = item.uploaded_at;
          returnValue.weights = item.weights;
          returnValue.site =
            item.contract && item.contract.collectionSite
              ? item.contract.collectionSite.Name
              : "";
          returnValue.expectedDate = item.expectedDate;
          returnValue.driver = item.driver;
          returnValue.vehicles = item.vehicles;
          returnValue.externalWeighbridgeReadings =
            item.externalWeighbridgeReadings;
          returnValue.etaDateTime = item.etaDateTime;
          returnValue.intendedTippingZone = item.intendedTippingZone
            ? item.intendedTippingZone.name
            : "";
          return returnValue;
        });
        let combinedResult = result.concat(transferNoteResult);
        if (this.lastRun == lastRun) {
          this.documentSearchResults = combinedResult.map((item) => {
            if (item.hasOwnProperty("transferNoteNumber")) {
              return item;
            }
            if (item.hasOwnProperty("qcNumber")) {
              return item;
            }
          });
          this.documentSearching = false;
        }
        console.log(this.documentSearchResults, "COMBINATION");
        console.log("searchForMainSystemDocuments : result : ", result);
      } catch (ex) {
        if (this.lastRun == lastRun) {
          this.documentSearching = false;
        }
      }
    },

    removeSelectedDocumentRecord(item) {
      this.selectedDocuments = this.selectedDocuments.filter(
        (record) => record.documentNumber != item.documentNumber
      );
      this.$forceUpdate();
    },

    populateBasedOnDocumentSelection() {
      // Populates supplier, driver and vehicle based on the
      // document(s) selected by the user

      console.log(
        "populateBasedOnDocumentSelection : ",
        this.selectedDocuments
      );

      if (this.computedCanUseDriver) {
        this.ticket.DriverName =
          `${this.selectedDocuments[0].driver.Forename} ` +
          `${this.selectedDocuments[0].driver.Surname}`;
      } else {
        this.ticket.DriverName = "";
      }
      console.log("ComputedCanUseTractor : ", this.computedCanUseTractor);
      if (this.computedCanUseTractor) {
        this.ticket.VehicleReg = this.selectedDocuments[0].vehicles.find(
          (record) => record.UnitType.toUpperCase() == "TRACTOR UNIT"
        ).RegNumber;

        this.handleTractorChange(
          this.selectedDocuments[0].vehicles.find((record) => {
            return record.UnitType.toUpperCase() == "TRACTOR UNIT";
          }).RegNumber
        );
        console.log(
          "populateBasedOnDocumentSelection we have handledTractorChange()"
        );
      } else {
        this.ticket.VehicleReg = "";
        this.handleTractorChange("");
      }

      if (this.computedCanUseTrailer) {
        console.log(
          "populateBasedOnDocumentSelection can use trailer: ",
          this.computedCanUseTrailer
        );

        this.ticket.TrailerNumber = this.selectedDocuments[0].vehicles.find(
          (record) => record.UnitType.toUpperCase() != "TRACTOR UNIT"
        ).RegNumber;

        this.handleTrailerChange(
          this.selectedDocuments[0].vehicles.find(
            (record) => record.UnitType.toUpperCase() != "TRACTOR UNIT"
          ).RegNumber
        );
      } else {
        this.ticket.TrailerNumber = "";
        this.handleTrailerChange("");
      }

      if (
        this.computedCanUseCompany === true &&
        this.selectedDocuments.length > 0
      ) {
        if (
          this.selectedDocuments &&
          this.selectedDocuments.length &&
          this.selectedDocuments[0].documentType === "QC DOCUMENT"
        ) {
          this.ticket.CompanyName =
            this.selectedDocuments[0].supplier.Name +
            // If we have a supplier site, then add this onto the end of the supplier name
            (this.selectedDocuments[0].supplierSite &&
            this.selectedDocuments[0].supplierSite.Name
              ? " / " + this.selectedDocuments[0].supplierSite.Name
              : "");
        } else {
          // This is a transfer note, enter the site from and site to as the company name

          console.log(
            "populateBasedOnDocumentSelection : ",
            this.selectedDocuments[0]
          );

          try {
            this.ticket.CompanyName =
              `From ${this.selectedDocuments[0].sourceWarehouse} To ` +
              `${this.selectedDocuments[0].destinationWarehouse}`;
          } catch (e) {
            console.log("populateBasedOnDocumentSelection : ", e.stack || e);
          }
        }
      } else {
        this.ticket.CompanyName = "";
      }
      // else if (
      //   this.computedCanUseCompany === false &&
      //   this.selectedDocuments.length
      // ) {
      //   console.log("CANNOT USE");
      //   this.ticket.CompanyName = "";
      // }

      this.applyTareWeightFromVehicles();
    },

    createDocumentRefFromSelectedDocuments() {
      let returnValue = "";
      for (let document of this.selectedDocuments) {
        if (document.documentType === "QC DOCUMENT") {
          returnValue += `QC${document.qcNumber.toString().padStart(6, "0")} `;
        }
        if (document.documentType === "TRANSFER NOTE") {
          returnValue += `TR${document.transferNoteNumber
            .toString()
            .padStart(6, "0")} `;
        }
      }

      return returnValue;
    },

    isDocumentReadOnly(document) {
      // The readonly basis depends on the documents that the
      // user has already selected (if any)

      // We don't have anything selected
      if (this.selectedDocuments.length === 0) {
        return false;
      }

      // One transfer note selected, nothing else can be selected
      if (
        this.selectedDocuments.find(
          (record) =>
            record.documentType === "TRANSFER NOTE" &&
            record._id.toString() !== document._id.toString()
        )
      ) {
        // If one transfer note is selected, then we can't select any other transfer notes
        return true;
      }

      // QC Document selected but we are a transfer note
      if (
        this.selectedDocuments.find(
          (record) =>
            record.documentType === "QC DOCUMENT" &&
            record._id.toString() !== document._id.toString() &&
            document.documentType == "TRANSFER NOTE"
        )
      ) {
        // If one QC document is selected, then we can't select any transfer notes
        return true;
      }

      // Document with Twin Pot Vehicle Selected
      // This should be the only thing that can be selected
      if (
        this.selectedDocuments.find(
          (record) =>
            this.twinPotCheck(record) &&
            record._id.toString() !== document._id.toString()
        )
      ) {
        return true;
      }

      // Document is a twin pot and we have non-twin pot select3ed
      if (
        this.selectedDocuments.find(
          (record) => !this.twinPotCheck(record) && this.twinPotCheck(document)
        )
      ) {
        return true;
      }
    },
  },
  watch: {
    dialog: async function(newvalue) {
      if (newvalue) {
        this.ticket.VehicleReg = "";
        this.ticket.TrailerNumber = "";
        this.tractorTare = 0.0;
        this.trailerTare = 0.0;
        this.ticket = Object.assign(this.selectedTicket);
        await this.lookupPreSelects();
        this.editTicketCheckTare();
        this.documentSearchResults = [];
        this.selectedDocuments = this.selectedTicket.QCDocuments || [];
      }
    },
    "ticket.DocumentRef": async function(newValue, oldValue) {
      // Need to search for QC documents from the main system *if* the
      // program is linked to the Main QC Document System.
      if (this.systemLinkedToQCSystem) {
        let result = await this.debouncedSearch(newValue);
      }
    },
    selectedDocuments: async function(newValue, oldValue) {
      if (!(this.ticket && this.ticket._id)) {
        this.populateBasedOnDocumentSelection();
      }
    },

    twinPotDocumentSelected: async function(newValue, oldValue) {
      if (newValue) {
        this.ticket.TareWeightKG = 0.0;
      }
    },
    "ticket.InboundType": async function(newValue, oldValue) {
      this.applyTareWeightFromVehicles();
    },

    "ticket.VehicleReg": async function(newValue, oldValue) {
      if (newValue) {
        this.ticket.VehicleReg = newValue.trim().toUpperCase();
      }
    },
    "ticket.TrailerNumber": async function(newValue, oldValue) {
      if (newValue) {
        this.ticket.TrailerNumber = newValue.trim().toUpperCase();
      }
    },
  },
};
</script>
