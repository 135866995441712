<template>
  <div>

    <v-card
      flat
      color="primary"
      dark
    >
      <v-card-title>
        Current Weight Readings
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
              cols='12'
              xs='12'
              md='12'
            >

              <v-data-table
                class="transparent"
                height="120px"
                dense
                :items="instruments"
                :headers="[
                    {text : 'Weighbridge', value : 'name'},
                    {text : 'Weight', value : 'lastReading'},
                    {text : 'Last Refreshed', value : 'datetime'},
                  ]"
                disable-pagination
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import SelectFunctions from "@/select/SelectFunctions";
import dayjs from "dayjs";

export default {

  mounted ()
  {
    this.instruments = JSON.parse(process.env.VUE_APP_WEIGHBRIDGE_INSTRUMENTS);

    this.instruments = this.instruments.map(record =>
    {
      record.lastReading = 0.00
      record.datetime = null; // the last reading date / time
      return record;
    })

    this.getWeightReadings();
    this.timerID = setInterval(() => { this.getWeightReadings() }, 5000);

  },

  beforeDestroy ()
  {
    clearInterval(this.timerID);
  },

  methods:
  {
    async getWeightReadings ()
    {
      for (let record of this.instruments)
      {
        try
        {
          let response = await fetch(
            record.address
            + `/LAST_READING`,
            {
              method: 'GET'
            }
          );

          if (!(response && response.ok)) { throw "Invalid return from server : " + response.status }

          let result = await response.json()

          console.log("getWeightReadings : result :", result);

          // update the record with the weight readings:
          try
          {
            if (result.lastReading === "") { throw "Invalid reading returned " }
            record.lastReading = Number(result.lastReading).toString().concat(" KG");
          }
          catch (e)
          {
            record.lastReading = "Unknown";
            console.error("getWeightReadings : caught error in weight number conversion :  ", e)
          }
          record.datetime = dayjs();

          this.$forceUpdate();

        }
        catch (e)
        {
          console.error("getWeightReadings : caught error : ", e);
        }

      }
    },

  },

  data: () =>
  {
    return {
      instruments: [],
      timerID: null,
    }
  }
}
</script>