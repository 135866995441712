<template>
  <div>
    <!-- <v-app-bar dark>
      <v-toolbar-title>Weighbridge application</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn elevation="0">Log out</v-btn>
    </v-app-bar> -->

    <v-container id="content-container" class="mt-6" fluid>
      <v-row>
        <v-col cols="12" xs="12" md="12">
          <v-btn
            xxoutlined
            color="success"
            @click="createNewTicket"
            large
            class="mr-2"
            >NEW TICKET</v-btn
          >
        </v-col>
      </v-row>

      <v-row class="mb-8">
        <v-col cols="12" xs="12" md="12">
          <live-readings />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" md="12">
          <ticket-list
            @openWeighingDialog="openWeighingDialog"
            :ticketList="ticketList"
            @reloadTicketList="loadTickets"
            :dataVersion="dataVersion"
            ref="ticketListForm"
          >
          </ticket-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" md="12">
          <span class="text-caption">App Version :{{ appVersion }}</span>
        </v-col>
      </v-row>
    </v-container>

    <weighing-dialog
      :dialog="weighingDialog"
      :selectedTicket="selectedTicket"
      @onClose="closeWeightinDialog"
      @onSaveNewTicket="addNewTicket"
      @onUpdateTicket="updateTicket"
    >
    </weighing-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import SelectFunctions from "@/select/SelectFunctions";
import TicketList from "../components/TicketList.vue";
import WeighingDialog from "../components/WeighingDialog.vue";

import LiveReadings from "@/components/LiveReadings.vue";

import { version } from "../../package";

export default {
  components: {
    TicketList,
    WeighingDialog,
    LiveReadings,
  },

  data() {
    return {
      processingDate: null,
      ticketList: [],

      selectedTicket: {},
      weighingDialog: false,
      appVersion: version,
      dataVersion: null,
    };
  },

  computed: {},

  mounted() {
    this.dataVersion = this.getNewTimeStamp();
    this.processingDate = dayjs(dayjs().format("YYYY-MM-DD")).toISOString();
    this.loadTickets();
  },

  methods: {
    createNewTicket() {
      var oNewTicket = {
        CompanyName: "",
        DriverName: "",
        VehicleReg: "",
        TrailerNumber: "",
        Status: "",
        InboundType: "",
        OutboundType: "",
        InboundWeightKG: 0,
        OutboundWeightKG: 0,
        InboundSerial: "",
        InboundInstrument: "",
        OutboundSerial: "",
        OutboundInstrument: "",
        InboundComment: "",
        OutboundComment: "",
        created_at: "",
        updated_at: "",
        TicketNumber: 0,
        FuelTankWeightKG: 0.0,
        TareWeightKG: 0.0,
        id: "",
      };
      this.openWeighingDialog(oNewTicket);
    },

    openWeighingDialog(oTicket) {
      console.log(oTicket, "OTICKET");
      this.weighingDialog = true;
      this.selectedTicket = oTicket;
      console.log(oTicket);
    },
    closeWeightinDialog() {
      this.weighingDialog = false;
      window.location.reload();
    },

    async addNewTicket(oTicket) {
      var response = await this.sendNewTicket(oTicket);
      this.weighingDialog = false;

      window.location.reload();

      try {
        this.$refs.ticketListForm.resetDates();
      } catch (e) {
        console.log(e);
      }

      this.loadTickets();
    },

    async updateTicket(oTicket) {
      var response = await this.sendUpdateTicket(oTicket);
      this.weighingDialog = false;
      window.location.reload();
      this.loadTickets();
    },

    async loadTickets() {
      this.dataVersion = this.getNewTimeStamp();

      // try
      // {
      //   let response = await SelectFunctions.fetch(
      //     process.env.VUE_APP_BACKEND_ENDPOINT + "tickets",
      //     { credentials: "include" }
      //   );

      //   if (!(response && response.ok))
      //   {
      //     throw "Invalid response from server : " + response.status;
      //   }

      //   let result = await response.json();
      //   this.ticketList = result;
      // } catch (e)
      // {
      //   console.log("loadTickets : caught error : ", e);
      // }
    },

    async sendNewTicket(oObj) {
      console.log(oObj, "OBJECT PAYLOAD");
      var oTicket = {
        CompanyName: oObj.CompanyName,
        DriverName: oObj.DriverName,
        InboundComment: oObj.InboundComment,
        InboundSerial: oObj.InboundSerial,
        InboundType: oObj.InboundType,
        InboundWeightKG: oObj.InboundWeightKG,
        TrailerNumber: oObj.TrailerNumber,
        VehicleReg: oObj.VehicleReg,
        DocumentRef: oObj.DocumentRef,
        InboundInstrument: oObj.InboundInstrument,
        TareWeightKG: oObj.TareWeightKG,
        QCDocuments: oObj.QCDocuments,
        TransferNotes: oObj.TransferNotes,
        FuelTankWeightKG: oObj.FuelTankWeightKG,
      };
      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "tickets",
        {
          method: "PUT",
          credentials: "include",
          body: JSON.stringify(oTicket),
        }
      );

      switch (response.status) {
        case 200:
          console.log("Success");
          var oResponseJSON = await response.json();
          console.log(oResponseJSON);
          break;
        case 400:
          console.log("Error400");
          break;
        default:
          console.log("default");
      }
      return response;
    },

    async sendUpdateTicket(oObj) {
      var oTicket = {
        OutboundComment: oObj.OutboundComment,
        OutboundSerial: oObj.OutboundSerial,
        OutboundType: oObj.OutboundType,
        OutboundWeightKG: oObj.OutboundWeightKG,
        DocumentRef: oObj.DocumentRef,
        OutboundInstrument: oObj.OutboundInstrument,
        CompanyName: oObj.CompanyName,
        DriverName: oObj.DriverName,
        TrailerNumber: oObj.TrailerNumber,
        VehicleReg: oObj.VehicleReg,
        DocumentRef: oObj.DocumentRef,
        TareWeightKG: oObj.TareWeightKG,
        FuelTankWeightKG: oObj.FuelTankWeightKG,
      };

      var response = await SelectFunctions.fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + "tickets/" + oObj.id,
        {
          method: "PATCH",
          credentials: "include",
          body: JSON.stringify(oTicket),
        }
      );

      switch (response.status) {
        case 200:
          console.log("Success");
          var oResponseJSON = await response.json();
          console.log(oResponseJSON);
          break;
        case 400:
          console.log("Error400");
          break;
        default:
          console.log("default");
      }

      return response;
    },

    getNewTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
};
</script>

<style scoped></style>
